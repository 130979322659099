/* body {
  height: 100vh;
  margin: 0;
  position: relative;
} */

canvas {
  display: 100vw;
  height: 100vh;
}

.hovered {
  cursor: pointer;
}

.popup {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}


.Project-container {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100vh;
  width: 100%;
}

.Project-text {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  z-index: 1;
  color: black;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-bottom: 0;
}

.Project-text h1 {
  margin: 0;
  font-size: 2rem;
  letter-spacing: 0.2rem;
}

.Project-text p {
  margin: 0.5rem 0 0;
  font-size: 1rem;
  color: silver;
  white-space: pre-line;
  padding: 0;
  margin: 0;
}

.popup {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.popup.show {
  opacity: 1;
}

.hovered {
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.conic {
  position: relative;
  z-index: 0;
  width: 110px;
  height: 100px; 
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  border: solid 2px #b1b5c7 !important;
  background: none !important;
  color: #606060 !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1.8px;
  transition: all 0.25s ease !important;
  text-align: center; 
}


.conic-title {
  font-size: 1rem; 
  /* width: 0%; */
}

.conic-number {
  font-size: 1.2rem; 
  font-weight: bold; 
}

.conic:hover {
  box-shadow: 1px 1px 8px #5d707b !important;
  color: #a0afb7 !important;
  text-shadow: 0 0 8px #c4ced2 !important;
}

.box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 70px; 
  width: 100%;
}

.conic-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: none !important;
  color: #606060 !important;
  border: solid 1px #b1b5c7 !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1.8px;
  padding: 0;
  transition: all 0.25s ease !important;
  z-index: 10;
  outline: none;
}

.conic-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10% !important;
  width: 60px;
  height: 1px;
  transform: translateY(-1px) !important;
  background: #7c909c !important;
  transition: all 0.25s ease !important;
}

.conic-button:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px) !important;
  background: #7c909c !important;
  transition: all 0.25s ease !important;
}

.conic-button:hover {
  box-shadow: 1px 1px 8px #5d707b !important;
  color: #a0afb7 !important;
  text-shadow: 0 0 8px #c4ced2 !important;
}

.title-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100;
}

.popup img {
  max-width: 100%;
  height: auto;
}

.box-container {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .box-container {
    bottom: 10%;
  }
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
}

.slide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 1;
}

.prev {
  left: 200px;
}

.next {
  right: 200px;
}
@media (max-width: 576px) {
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }

  .Project-text{
    top: 10%;
  }

  .box-container {
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .popup img {
    max-width: 0%; /* Reduce the width of the images */
    height: auto;
  }
  
  .conic {
    width: calc(33% - 10px); /* Adjust the width to fit three items per row */
    margin: 5px; /* Adjust the margin for spacing */
  }
  
  .conic-number {
    font-size: 1rem;
  }
}


