@font-face {
    font-family: 'Helvetica Neue';
    src: url('/public/fonts/HelveticaNeueThin.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('/public/fonts/HelveticaNeueLight.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/public/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}
  
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/public/fonts/HelveticaNeueHeavy.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}
  
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/public/fonts/HelveticaNeueBold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}
  
@font-face {
    font-family: 'Helvetica Neue';
    src: url('/public/fonts/HelveticaNeueBlack.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

/* Font Styles */
.font-thin {
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 0.8rem;
}
  
.font-light {
    font-family: 'Helvetica Neue';
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 1.2rem;
}
  
.font-medium {
    font-family: 'Helvetica Neue';
    font-weight: 500;
    font-size: 1.6rem;
}
  
.font-heavy {
    font-family: 'Helvetica Neue';
    font-weight: 600;
    letter-spacing: 1.75px;
    font-size:  2rem;
}
  
.font-bold {
    font-family: 'Helvetica Neue';
    font-weight: 700;
}

.font-black {
    font-family: 'Helvetica Neue';
    font-weight: 800;
    letter-spacing: 1.25px;
}

.headline-list {
  list-style: none;
  padding: 0;
}

.headline-list li {
  margin-bottom: 1rem;
  line-height: 1.5;
  margin-bottom: 0.5rem; 
}

.headline-list li strong {
  display: block;
  font-weight: bold;
}

.hero-section img {
  max-height: 500px;
  object-fit: cover;
}

.project-gallery img {
  width: 100%;
  height: auto;
  border: none;
}
.text-justify {
    text-align: justify;
  }
