/* Hero Section */
._heroSection {
  background-color: #fcfcfc;
  color: #1d1d1d;
  height: 100vh;
  width: 100%;
}

._heroSection ._cta {
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: #a6a6a6;
  border: none;
  box-shadow: -5px 10px 5px #969696;
}

._heroSection ._cta:hover {
  background-color: #bebebe;
}

._heroSection h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

._heroSection p {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

._heroSection img {
  height: 100%;
  width: 100%;
  border-radius: 0;
  box-shadow: 11px 13px 7px #969696;
}

/* How to Order */
._orderSteps {
  height: auto;
}

._orderSteps img {
  border-radius: 0;
  background-size: cover;
  background-position: center;
  height: 300px;
  z-index: -1;
  position: relative;
}

._bannerContainer {
  background-image: url('../../assets/btnScarves/btnScarves1.png');
  background-size: cover;
  background-position: center;
  height: 300px;
  position: relative;
}

._bannerContainer:hover {
  transform: scale(1.0125);
  transition: transform 500ms ease-in-out;
}

._bannerMask {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  cursor: pointer;
}

._bannerMask img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
}

._serviceContainer:hover {
  transform: scale(1.0125);
  transition: transform 500ms ease-in-out;
}

._maskText {
  position: relative;
  z-index: 1;
  font-size: 1.8rem;
  font-weight: 800;
  letter-spacing: 1.25px;
  cursor: pointer;
}

/* Promo Section */
._promoContainer {
  background-color: #f5f5f5;
}

._promoTitle {
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 2rem;
    color: #1d1d1d;
}

._promoContainer .row {
  display: flex;
  flex-wrap: wrap;
}

._promoContainer .col {
  display: flex;
}

._promoContainer .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

._customCard {
  height: 100%;
}

._cardBody {
  display: flex;
  flex-direction: column;
  height: 100%;
}

._promoContainer button {
  border-radius: 15px;
  font-weight: 500;
  font-size: 1.05rem;
}

/* Contact Our Sales Section */
section ._ourSales {
  height: 400px;
  width: 100%;
  display: flex;
}

._ourSales ._cta {
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: #a6a6a6;
  border: none;
  box-shadow: -5px 10px 5px #969696;
}

._ourSales ._cta:hover {
  background-color: #bebebe;
}

._ourSales h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

._ourSales p {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

._ourSales img {
  border-radius: 0;
  box-shadow: 11px 13px 7px #969696;
}

._ourSales .img-fluid {
  max-width: 100%;
}

/* Mobile Responsive */
@media (max-width: 576px) {
  ._heroSection ._heroPricing {
    display: flex;
    flex-direction: column-reverse;
  }

  ._heroSection img {
    box-shadow: none;
  }

  ._heroSection h1 {
    text-align: center;
    padding-top: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500; 
  }

  ._heroSection h2 {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
  }

  ._heroSection ._cta {
    display: block;
    margin: auto;
    box-shadow: none;
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  ._promoContainer ._promoTitle {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 2rem;
    color: #1d1d1d;
  }

  ._promoContainer .card-title {
    font-size: 2rem;
  }

  ._promoContainer .card-subtitle h5 {
    font-size: 1.3rem;
  }

  ._promoContainer .card-subtitle h2 {
    font-size: 1.8rem;
  }

  ._promoContainer ul li {
    font-size: 1rem;
  }

  ._ourSales img {
    box-shadow: none;
    margin-bottom: 1.2rem;
    flex-direction: column-reverse;
  }

  ._ourSales ._cta {
    display: block;
    margin: auto;
    box-shadow: none;
    font-size: 0.9rem;
    padding: 8px 12px;
    font-weight: 500;
  }

  ._ourSales h1 {
    text-align: center;
    font-size: 1.8rem;
  }
}

/* How to Order */
@media (max-width: 768px) {
  ._orderSteps ._serviceContainer {
    margin-bottom: 20px;
  }
}

/* Contact Our Sales Section */
@media (max-width: 768px) {
  ._salesRow {
    flex-direction: column;
  }

  ._salesRow .col-md-6 {
    margin-bottom: 1rem;
  }

  ._salesRow .col-md-6:nth-child(1) {
    order: 2;
  }

  ._salesRow .col-md-6:nth-child(2) h1 {
    order: 3;
    margin-top: 1rem;
  }

  ._salesRow .col-md-6:nth-child(2) ._cta {
    order: 4; /* Tombol di urutan ketiga */
    display: block;
    margin: auto;
    box-shadow: none;
    font-size: 0.9rem;
    padding: 8px 12px;
    font-weight: 500;
  }
}