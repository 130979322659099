.carousel-item-custom {
  height: 600px; /* Ubah sesuai dengan tinggi yang diinginkan */
  overflow: hidden;
}

.carousel-item-custom img {
  height: 100%;
  object-fit: cover;
}

.mbr-section-title {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
}

.mbr-fonts-style {
  font-family: 'Helvetica Neue', sans-serif;
}

.mbr-bold {
  font-weight: bold;
}

.display-2 {
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: 1rem;
}

.filter_projects .nav-link {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  margin-right: 10px;
  border-radius: 20px;
  padding: 15px 20px;
}

.filter_projects .nav-link.active {
  background-color: #98a0a5;
  color: #7e888e;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.project-images {
  transition: opacity 0.5s ease-in-out;
}

.project-images.fade-out {
  opacity: 0;
}

.project-images.fade-in {
  opacity: 1;
}

.image-container {
  transition: transform 0.3s ease;
}

.image-container:hover {
  transform: scale(1.05);
}

.filter_projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.separator {
  color: #333;
  margin: 0 10px;
  font-weight: normal;
  font-size: 18px;
}

.all_projects_content {
  text-align: center;
  color: #1d1d1d;
}

.all_projects_content h1 {
  text-decoration: underline;
}

.all_projects_content .filter_projects {
  justify-content: center;
}

.all_projects_content .border-box {
  color: red !important;
  gap: 5;
}

.image-fluid {
  width: 100%;
  height: auto;
  max-height: 250px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.image-container {
  position: relative;
  height: 250px; 
  transition: transform 0.3s ease;
  padding: 0 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  object-fit: cover;
}

.image-wrapper:hover img {
  opacity: 0.7;
}

.image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-wrapper:hover .image-caption {
  opacity: 1;
}

.filter_projects .nav-link {
  border: none;
  padding: 10px;
  margin: 5px;
  text-align: center;
  color: #1d1d1d;
}

.filter_projects .nav-link:hover {
  background-color: #e9ecef;
  border: none;
}

.filter_projects .nav-link.active {
  color: white;
  text-decoration: underline;
}

@media only screen and (max-width: 576px) {
  ._carousel h3 {
    font-size: 18px;
  }

  ._carousel h4 {
    font-size: 15px;
  }

  .all_projects_content h2 {
    font-size: 30px;
  }

  .filter_projects .nav-link {
    display: flex;
    font-size: 0.785rem;
    font-weight: 600;
  }

  .separator {
    display: none;
  }
}