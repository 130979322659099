.welcome-container {
  height: 100vh;
  text-align: center;
  overflow: hidden;
  padding-top: 50px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome-image-container {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.welcome-section-container {
  margin-bottom: 0; /* Add this line */
  padding-bottom: 0; /* Add this line */
  margin: 0;
  padding: 0;
}

.welcome-image {
  width: 250px;
  height: auto;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  opacity: 0;
  transform: scale(0.9);
}

.images-fluid {
  width: 200px;
}

.welcome-text {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0.9);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 3rem;
}

.welcome-text span {
  margin: 0 16px;
}

.welcome-text .black {
  color: black;
}

.welcome-text .blue {
  color: #003366;
}

.description {
  opacity: 0;
  transition: opacity 1s ease-in-out 1.5s;
}

.animate .welcome-image {
  opacity: 1;
  transform: scale(1);
}

.animate .welcome-text {
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.5s;
}

.animate .description {
  opacity: 1;
}

/* .hide .welcome-image,
.hide .welcome-text,
.hide .description {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
} */

.contact-us-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  z-index: 10;
}

.welcome-section-container {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.canvas-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; 
  padding: 0; 
}

/* Media Queries for Responsiveness */

@media (max-width: 1200px) {
  .welcome-text {
    font-size: 2.5rem;

    margin: 0;
    padding: 0;
  }
  .welcome-image {
    width: 200px; /* Adjust the size for larger tablets and desktops */
    margin: 0;
    padding: 0; }

    .canvas-container{
      margin: 0;
      padding: 0;
    }
  
}

@media (max-width: 992px) {
  .welcome-text {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }
  .welcome-image {
    width: 150px;
    margin: 0;
    padding: 0;
  }
  .canvas-container{
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .welcome-text {
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .welcome-image {
    width: 120px; 
    margin: 0;
    padding: 0;
  }
  .canvas-container{
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 576px) {
  .welcome-container {
    text-align: center;
    overflow: hidden;
    padding-top: 50px;
    padding: 0;
    margin-top: -10%;
    margin-bottom: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .welcome-text {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }
  .welcome-image {
    width: 230px; 
    margin: 0;
    padding: 0;
  }
  .canvas-container{
    margin-top: -130%;
    margin-bottom: -60%;
    padding: 0;
  }

  .description{
    font-size: 1rem;
  }
}