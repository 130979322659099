.contact-container {
  margin-top: 50px;
}

.contact-info {
  margin-bottom: 30px;
}

.contact-info p {
  margin-bottom: 5px;
  font-size: 16px;
}

.contact-info iframe {
  border: 0;
  width: 100%;
  height: 300px;
}

.contact-form col {
  height: 1000px;
}

.contact-form h2 {
  margin-bottom: 20px;
  font-weight: bold;
}

.contact-form .form-control {
  margin-bottom: 15px;
}

.contact-form .btn {
  display: flex;
  width: 100%;
}

.contact-info h2,
.contact-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-form h2 {
  text-shadow: 2px 3px 3px #999999;
}

.contact-info p {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: start;
}

.contact-info iframe {
  border: 5px;
  width: 100%;
  height: 300px;
}

.contact-form form {
  border: 3px solid #1f1f1f;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.contact-form form .form-group {
  margin-bottom: 15px;
}

.contact-form form .form-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.contact-form form .form-control {
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #ccc;
  padding: 10px;
}

.contact-form form .btn {
  width: 50%;
  background-color: #1d1d1d;
  border: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contact-form form .btn:hover {
  background-color: #393939;
}

._btnSubmit {
  text-align: center;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767.98px) {
  .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .row .contact-form{
    width: 100%;
  }

  .contact-form ._formControl .btn {
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
  }

  .row .contact-info {
    width: 100%;
  }

  .contact-info {
    padding-top: 2rem;
  }

  .contact-info h2,
  .contact-info p {
    text-align: center;
  }
}