.navbar-light .navbar-toggler {
  border-color: transparent;
}

@media only screen and (max-width: 575.98px) {

}
 
@media (max-width: 992px) {
  .navbar-nav {
    text-align: right;
  }
}