.footer-container {
  width: 100%;
  background-color: #f9f9f9;
  padding: 10px 5;
  text-align: center;
  color: #888888;
}

.social-media-icons .social-icon {
  font-size: 20px;
  margin: 0 10px;
  color: #888888;
  transition: color 0.3s ease;
}

.social-media-icons .social-icon:hover {
  color: #555555;
}

.footer-links .footer-link {
  color: #888888;
  text-decoration: none;
  margin: 0 5px;
  transition: color 0.3s ease;
  font-size: medium;
}

.footer-links .footer-link:hover {
  color: #555555;
}

.copyright {
  font-size: 18px;
  font-weight: 500;
  margin-top: 6px;
}
